.content-info {
	@media ( min-width: $screen-sm-min ) {
	margin-bottom: $line-height-computed * 2;
	}
	color: white;
	a {
		color: white;
	}
	> .container {
		background-color: $fl-main-bg-color;
		@media ( min-width: $screen-sm-min ) {
		// margin-bottom: $line-height-computed * 2;
		border-right: 1px solid $fl-bg-border-color;
		// border-bottom: 1px solid $fl-bg-border-color;
		border-left: 1px solid $fl-bg-border-color;
		}
		> .inner {
			padding: 0 $grid-gutter-width / 2;
		}
		@media ( min-width: $screen-sm-min ) {
		&.container-2 {
			border-bottom: 1px solid $fl-bg-border-color;
		}
		}
	}
	.container-1 {

	}
		.sidebar-footer {
			border-bottom: $line-height-computed / 4 solid $fl-accent-color-2;
			background-color: $fl-accent-color-1;
			.widget {
				padding-top: $line-height-computed;
				padding-bottom: $line-height-computed;
				h3 {
					margin-top: 0;
					border-bottom: 1px solid white;
					color: white;
					font-size: $font-size-large;
				}
				a {
					text-decoration: none;
					&:hover,
					&:focus,
					&:active {
						text-decoration: underline;
					}
				}
			}
			.widget_nav_menu {
				ul {
					margin-left: 0;
					padding-left: 0;
					list-style: none;
				}
			}
		}
	.container-2 {
		
	}
		.smallprint {
			padding-top: $line-height-computed / 2;
			color: $gray-light;
			background-color: $fl-main-bg-color;
		}
			.secondary-nav {
				text-align: center;
				@media ( min-width: $screen-sm-min ) {
				text-align: right;
				}
				ul {
					margin-left: 0;
					padding-left: 0;
					list-style: none;
					> li {
						display: inline-block;
						> a {
							margin-right: 3px;
							padding-right: 5px;
							border-right: 1px solid $gray-light;
							display: inline-block;
						}
						&:last-child {
						> a {
							border-right: 0;
						}
						}
					}
				}
					a {
						color: $brand-primary;
					}
			}
			.copyright {
				text-align: center;
				@media ( min-width: $screen-sm-min ) {
					text-align: left;
				}
				p {
					margin-bottom: $line-height-computed / 2;
				}
			}
}

