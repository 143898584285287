html,
body {
	background-color: $fl-bg-color;
}
a {
	color: lighten( $fl-accent-color-1, 15% );
	// font-weight: 700;
	text-decoration: underline;
	&:hover,
	&:focus,
	&:active {
		color: lighten( $fl-accent-color-1, 30% );
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $fl-accent-color-1;
}
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: $line-height-computed;
}
p {
	margin-bottom: $line-height-computed;
}
.page-header {
	margin-top: 0;
	padding-bottom: 0;
	border-color: $fl-accent-color-1;
	h1 {
		margin-bottom: 0;
	}
}
.wrap {
	padding-top: $line-height-computed;
	padding-bottom: $line-height-computed;
	background-color: $fl-main-bg-color;
}
@media ( min-width: $screen-sm-min ) {
.wrap {
	border-right: 1px solid $fl-bg-border-color;
	border-left: 1px solid $fl-bg-border-color;
}
}
.boxouts {
	padding: $line-height-computed $grid-gutter-width;
	background-color: $fl-accent-color-1;
	.lead-paragraph {
		margin-bottom: $line-height-computed;
		color: white;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 0;
			color: white;
		}
	}
	.boxout {
		.inner {
			padding: $line-height-computed / 2 $grid-gutter-width / 2;
			color: $text-color;
			background-color: white;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: $line-height-computed / 2;
		}
	}
}
.sidebar {
	padding-top: $line-height-computed;
	.widget {
		h3 {
			border-bottom: 1px solid $fl-accent-color-1;
			color: $fl-accent-color-1;
			font-size: $font-size-large;
		}
		.table {
			> tbody {
				> tr {
					>th,
					>td {
						border: 0;
						padding-left: 0;
					}
				}
			}
		}
	}
}
.panel-title {
	a {
		text-decoration: none;
	}
}
