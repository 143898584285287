.banner {
	margin-bottom: 0;
	border: 0;
	background-color: $fl-bg-color;
	> .container {
		background-color: $fl-main-bg-color;
		@media ( min-width: $screen-sm-min ) {
		border-right: 1px solid $fl-bg-border-color;
		border-left: 1px solid $fl-bg-border-color;
		}
	}
		.library {
			text-align: center;
			img {
				max-width: 100%;
				margin-top: $line-height-computed;
				@media ( min-width: $screen-sm-min ) {
				margin-top: $line-height-computed * 2.5;
				padding-left: $grid-gutter-width;
				}
			}
			@media ( min-width: $screen-sm-min ) {
			text-align: left;
			}
		}
			.brand {
				display: inline-block;
				float: none;
				img {
					max-width: 100%;
				}
			}
		// 	.navbar-header {
		// 		text-align: center;
		// 		@media ( min-width: $screen-sm-min ) {
		// 		text-align: default;
		// 		}
		// 		.navbar-brand {
		// 			display: inline-block;
		// 			float: none;
		// 			img {
		// 				max-width: 100%;
		// 				height: auto;
		// // 				// @media ( min-width: $screen-sm-min ) {
		// // 				// margin-top: $line-height-computed * 2;
		// // 				// margin-bottom: $line-height-computed * 2;
		// // 				// }
		// 			}
		// 		}
		// 	}
	// 		// .navbar-brand {
	// 		// 	// text-align: center;
	// 		// 	display: inline-block;
	// 		// 	float: none;
	// 		// }
		.fofl {
			text-align: center;
			@media ( min-width: $screen-sm-min ) {
			text-align: right;
			}
			img {
				max-width: 100%;
				margin-top: $line-height-computed;
				margin-bottom: $line-height-computed;
				@media ( min-width: $screen-sm-min ) {
				padding-right: $grid-gutter-width;
				}
			}
		}
		.navbar {
			min-height: 56px;
			margin-bottom: 0;
			border-top: $line-height-computed / 4 solid $fl-accent-color-2;
			// background-color: $fl-accent-color-1;
			.navbar-nav {
				> li,
				> .active {
					> a {
						text-decoration: none;
						&:hover,
						&:focus,
						&:active {
							text-decoration: underline;
						}
					}
				}
			}
		}
					.dropdown-menu {
						> li {
							> a {
								text-decoration: none;
								&:hover,
								&:focus,
								&:active {
									text-decoration: underline;
								}
							}
						}
					}
			.navbar-toggle {
				position: absolute;
				z-index: 2000;
				right: 0;
			}
			.navbar-collapse {
				// padding-right: 0;
				// padding-left: 0;
				// background-color: $fl-accent-color-1;
				border: 0;
				@media ( min-width: $screen-sm-min ) {
				padding-right: 0;
				padding-left: 0;
				}
			}
				.nav {
					margin-top: 0;
					margin-bottom: 0;
				}
	// .navbar-toggle {
	// 	border: 1px solid $navbar-default-toggle-border-color;
	// 	background-color: $navbar-default-toggle-icon-bar-bg;
	// }

	// .navbar-toggle {
	// 	border-color: $navbar-default-toggle-border-color;
	// 	background-color: $navbar-default-bg;
	// 	.icon-bar {
	// 		background-color: $navbar-default-toggle-icon-bar-bg;
	// 	}
	// 	&:hover,
	// 	&:focus,
	// 	&:active {
	// 		background-color: $navbar-default-toggle-hover-bg
	// 	}
	// 	// position: relative;
	// 	// float: right;
	// 	// margin-right: $navbar-padding-horizontal;
	// 	// padding: 9px 10px;
	// 	// @include navbar-vertical-align(34px);
	// 	// background-color: transparent;
	// 	// background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	// 	// border: 1px solid transparent;
	// 	// border-radius: $border-radius-base;

	// 	// // We remove the `outline` here, but later compensate by attaching `:hover`
	// 	// // styles to `:focus`.
	// 	// &:focus {
	// 	// 	outline: 0;
	// 	// }

	// 	// // Bars
	// 	// .icon-bar {
	// 	// 	display: block;
	// 	// 	width: 22px;
	// 	// 	height: 2px;
	// 	// 	border-radius: 1px;
	// 	// }
	// 	// .icon-bar + .icon-bar {
	// 	// 	margin-top: 4px;
	// 	// }

	// 	// @media (min-width: $grid-float-breakpoint) {
	// 	// 	display: none;
	// 	// }
	// }

}
